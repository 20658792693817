import request from "@/utils/request";
// 全部
export function getAllData(data) {
  return request({
    url: "/api/banner/getAllData.json", //全部
    method: "post",
    data,
  });
}
export function saveData(data) {
  return request({
    url: "/api/banner/saveData.json",
    method: "post",
    data,
  });
}
export function getDataInfo(data) {
  return request({
    url: "/api/banner/getDataInfo.json",
    method: "post",
    data,
  });
}
export function deleteData(data) {
  return request({
    url: "/api/banner/deleteData.json",
    method: "post",
    data,
  });
}
// 分页
export function getDataList(data) {
  return request({
    url: "/api/banner/getDataList.json",
    method: "post",
    data,
  });
}