import * as newsCenter from "@/api/newsCenter";
const actions = {
  getDataList({}, parm) {
    return new Promise((resolve, reject) => {
      newsCenter.getDataList(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  saveData({}, parm) {
    return new Promise((resolve, reject) => {
      newsCenter.saveData(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getDataInfo({}, parm) {
    return new Promise((resolve, reject) => {
      newsCenter.getDataInfo(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteData({}, parm) {
    return new Promise((resolve, reject) => {
      newsCenter.deleteData(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};


export default {
  namespaced: true,
  actions,
};
