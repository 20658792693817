import * as defaultTdk from "@/api/defaultTdk";
const actions = {
  getDefaultTdk({}, parm) {
    return new Promise((resolve, reject) => {
      defaultTdk.getDefaultTdk(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getDataList({}, parm) {
    return new Promise((resolve, reject) => {
      defaultTdk.getDataList(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  saveData({}, parm) {
    return new Promise((resolve, reject) => {
      defaultTdk.saveData(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getDataInfo({}, parm) {
    return new Promise((resolve, reject) => {
      defaultTdk.getDataInfo(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteData({}, parm) {
    return new Promise((resolve, reject) => {
      defaultTdk.deleteData(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
export default {
  namespaced: true,
  actions,
};
