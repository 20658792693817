import * as productCenter from '@/api/productCenter'
const actions = {
    getDataList({}, parm) {
    return new Promise((resolve, reject) => {
      productCenter.getDataList(parm).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  saveData({}, parm) {
    return new Promise((resolve, reject) => {
      productCenter.saveData(parm).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getDataInfo({}, parm) {
    return new Promise((resolve, reject) => {
      productCenter.getDataInfo(parm).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  deleteData({}, parm) {
    return new Promise((resolve, reject) => {
      productCenter.deleteData(parm).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  actions
}