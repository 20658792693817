import * as solution from '@/api/solution'
const actions = {
  getDataList({}, parm) {
    return new Promise((resolve, reject) => {
      solution.getDataList(parm).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  saveData({}, parm) {
    return new Promise((resolve, reject) => {
      solution.saveData(parm).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getDataInfo({}, parm) {
    return new Promise((resolve, reject) => {
      solution.getDataInfo(parm).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  deleteData({}, parm) {
    return new Promise((resolve, reject) => {
      solution.deleteData(parm).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getAllData({}, parm) {
    return new Promise((resolve, reject) => {
      solution
        .getAllData(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  actions
}