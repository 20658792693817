import request from '@/utils/request'

export function getDataList(data) {
  return request({
    url: '/api/productCenter/getDataList.json',
    method: 'post',
    data
  })
}
export function saveData(data) {
    return request({
      url: '/api/productCenter/saveData.json',
      method: 'post',
      data
    })
  }
  export function getDataInfo(data) {
    return request({
      url: '/api/productCenter/getDataInfo.json',
      method: 'post',
      data
    })
  }
  export function deleteData(data) {
    return request({
      url: '/api/productCenter/deleteData.json',
      method: 'post',
      data
    })
  }