import request from "@/utils/request";
// 全部
export function getAllData(data) {
  return request({
    url: "/api/solution/getAllData.json",
    method: "post",
    data,
  });
}
export function saveData(data) {
  return request({
    url: "/api/solution/saveData.json",
    method: "post",
    data,
  });
}
export function getDataInfo(data) {
  return request({
    url: "/api/solution/getDataInfo.json",
    method: "post",
    data,
  });
}
export function deleteData(data) {
  return request({
    url: "/api/solution/deleteData.json",
    method: "post",
    data,
  });
}
// 分页
export function getDataList(data) {
  return request({
    url: "/api/solution/getDataList.json",
    method: "post",
    data,
  });
}
