import request from "@/utils/request";

export function getDataList(data) {
  return request({
    url: "/api/sysDictionary/getDataList.json",
    method: "post",
    data,
  });
}
export function getAllData(data) {
  return request({
    url: "/api/sysDictionary/getAllData.json",
    method: "post",
    data,
  });
}
export function saveData(data) {
  return request({
    url: "/api/sysDictionary/saveData.json",
    method: "post",
    data,
  });
}
export function getDataInfo(data) {
  return request({
    url: "/api/sysDictionary/getDataInfo.json",
    method: "post",
    data,
  });
}
export function deleteData(data) {
  return request({
    url: "/api/sysDictionary/deleteData.json",
    method: "post",
    data,
  });
}
