import * as contact from "@/api/contact";
const actions = {
  getDataList({}, parm) {
    return new Promise((resolve, reject) => {
      contact.getDataList(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  saveData({}, parm) {
    return new Promise((resolve, reject) => {
      contact.saveData(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getDataInfo({}, parm) {
    return new Promise((resolve, reject) => {
      contact.getDataInfo(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteData({}, parm) {
    return new Promise((resolve, reject) => {
      contact.deleteData(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getContactInfo({}, parm) {
    return new Promise((resolve, reject) => {
      contact.getContactInfo(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
export default {
  namespaced: true,
  actions,
};
