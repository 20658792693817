import * as deletefile from "@/api/deletefile";
const actions = {
  deleteData({}, parm) {
    return new Promise((resolve, reject) => {
      deletefile.deleteData(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
export default {
  namespaced: true,
  actions,
};
