import request from "@/utils/request";

export function getDefaultTdk(data) {
  return request({
    url: "/api/defaultTdk/getDefaultTdk.json", //获取默认TDK
    method: "post",
    data,
  });
}
export function getDataList(data) {
  return request({
    url: "/api/defaultTdk/getDataList.json",
    method: "post",
    data,
  });
}
export function saveData(data) {
  return request({
    url: "/api/defaultTdk/saveData.json",
    method: "post",
    data,
  });
}
export function getDataInfo(data) {
  return request({
    url: "/api/defaultTdk/getDataInfo.json",
    method: "post",
    data,
  });
}
export function deleteData(data) {
  return request({
    url: "/api/defaultTdk/deleteData.json",
    method: "post",
    data,
  });
}
