<template>
  <div>
    <div class="hl-header">
      <div class="logo"></div>
      <div class="text">
        恒天翼城市管理系统
        <el-dropdown trigger="click">
          <span class="el-dropdown-link">
            <i class="el-icon-user-solid"></i
            ><span class="hl-userName">{{ userName }}</span>
            <i class="el-icon-arrow-down el-icon-caret-bottom"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <router-link to="/home/personal">
              <el-dropdown-item>个人设置</el-dropdown-item>
            </router-link>
            <el-dropdown-item divided @click.native="loginOut()"
              >退出系统</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  components: {},
  data() {
    return {}
  },
  computed: {
    userName() {
      return this.$store.getters.user.userName
    },
  },
  watch: {},
  created() {},
  mounted() {},
  destroyed() {},
  methods: {
    async loginOut() {
      this.$confirm('尊敬的用户您好,即将退出系统, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'info',
      })
        .then(() => {
          this.$store.dispatch('user/logout')
          this.$store.dispatch('tagsView/delAllViews')
          this.$router.push(`/login`)
          // 记录之前浏览过的页面
          // this.$router.push(`/login?redirect=${this.$route.fullPath}`)
        })
        .catch(() => {})
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/theme.scss';
.el-dropdown {
  display: inline-block;
  position: relative;
  position: absolute;
  right: $headerHeight * 0.4;
  font-size: $headerHeight * 0.4;
  color: #fff;
}

.el-dropdown .el-icon-caret-bottom {
  font-size: 1rem;
  top: -0.5rem;
  position: relative;
}
</style>
