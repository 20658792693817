<template>
  <div class="hl-login">
    <div class="hl-sys-title">
      <div>恒天翼城市管理系统</div>
    </div>
    <div class="hl-login-wrapper">
      <div class="hl-login-name">用户登录</div>
      <el-form
        ref="elForm"
        class="hl-login-form"
        :model="formData"
        :rules="rules"
        size="medium"
        label-width="100px"
      >
        <el-row>
          <el-form-item label-width="0" prop="userName">
            <el-input
              v-model="formData.userName"
              placeholder="请输入"
              clearable
              prefix-icon="el-icon-user"
              :style="{ width: '100%' }"
            ></el-input>
          </el-form-item>
          <el-form-item label-width="0" prop="password">
            <el-input
              v-model="formData.password"
              placeholder="请输入"
              clearable
              prefix-icon="el-icon-lock"
              show-password
              :style="{ width: '100%' }"
            ></el-input>
          </el-form-item>
          <!-- <el-row>
            <el-col :span="12">
              <el-form-item label-width="0" prop="code">
                <el-input
                  v-model="formData.code"
                  @keyup.enter.native="submitForm"
                  placeholder="请输入"
                  clearable
                  prefix-icon="el-icon-s-claim"
                  :style="{ width: '100%' }"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <img
                :src="src"
                @click="getCode"
                style="height: 36px; margin-left: 10px; cursor: pointer"
              />
            </el-col>
          </el-row> -->
        </el-row>
        <el-form-item size="large" style="margin-bottom: 0px">
          <el-button :loading="loading" type="primary" @click="submitForm"
            >登录</el-button
          >
          <el-button @click="resetForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import cryptoAES from '@/utils/cryptoAES'
export default {
  name: 'login',
  components: {},
  data() {
    return {
      loading: false,
      src: '',
      user: this.$store.getters.user,
      key: '',
      formData: {
        userName: undefined,
        password: undefined,
        // code: undefined,
      },
      rules: {
        userName: [
          {
            required: true,
            message: '请输入',
            trigger: 'blur',
          },
        ],
        password: [
          {
            required: true,
            message: '请输入',
            trigger: 'blur',
          },
        ],
        // code: [
        //   {
        //     required: true,
        //     message: '请输入',
        //     trigger: 'blur',
        //   },
        // ],
      },
    }
  },
  watch: {
    $route: {
      handler: function (route) {
        const query = route.query
        if (query) {
          this.redirect = query.redirect
          this.otherQuery = this.getOtherQuery(query)
        }
      },
      immediate: true,
    },
    'user.key': {
      handler(val) {
        this.key = val
      },
    },
  },
  created() {},
  mounted() {
    this.dynamicKey()
  },
  destroyed() {},
  methods: {
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== 'redirect') {
          acc[cur] = query[cur]
        }
        return acc
      }, {})
    },
    //获取验证码
    getCode() {
      this.src =
        process.env.VUE_APP_BASE_API +
        '/api/getKaptchaImage.json?version=' +
        Math.random()
    },
    submitForm() {
      this.$refs['elForm'].validate((valid) => {
        if (valid) {
          this.loading = true
          const copyForm = Object.assign({}, this.formData)
          const aesPassword = cryptoAES.encrypt(copyForm.password, this.key)
          copyForm.password = aesPassword
          this.$store
            .dispatch('user/login', copyForm)
            .then((response) => {
              this.loading = false
              if (response.success) {
                this.$router.push({
                  path: this.redirect || '/',
                  query: this.otherQuery,
                })
              } else {
                this.getCode()
                this.$message.error(response.message)
              }
            })
            .catch((error) => {
              console.log(error)
              this.getCode()
              this.loading = false
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    //获取AES加密的key
    dynamicKey() {
      this.$store
        .dispatch('user/dynamicKey')
        .then((response) => {
          if (response.success) {
            this.getCode()
          } else {
            this.$message.error(response.message)
          }
        })
        .catch((error) => {
          this.$message.error('网络异常')
          console.log(error)
        })
    },
    resetForm() {
      this.$refs['elForm'].resetFields()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/theme.scss';
.hl-sys-title {
  position: absolute;
  top: 7rem;
  left: 16rem;
  :nth-child(1) {
    font-size: 2.3rem;
    font-weight: bold;
    color: #fff;
    letter-spacing: 0.1rem;
  }
  :nth-child(2) {
    font-size: 1.3rem;
    color: #fff;
  }
}

.hl-login {
  width: 100%;
  height: 100%;
  background-image: $loginBg;
  background-size: 100% 100%;
  position: relative;
}
.hl-login-wrapper {
  width: 350px;
  background-color: #fff;
  padding: 1rem;
  border-radius: 10px;
  position: absolute;
  right: 7rem;
  top: 50%;
  transform: translateY(-40%);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
}
.hl-login-name {
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
}
.hl-login-form {
  width: calc(100% - 3.5rem);
  margin: 0 auto;
}
.el-input__inner {
  border: none !important;
  box-shadow: none !important;
  border-bottom: 1px solid #dadee2 !important;
  height: 50px !important;
  line-height: 50px !important;
  font-size: 1rem;
  text-indent: 15px;
}
.el-input__icon {
  color: rgb(29, 114, 254);
  font-size: 1.5rem;
}
</style>
