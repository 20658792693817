import router from './router'
import store from './store'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

NProgress.configure({
  showSpinner: false
})

const whiteList = ['/login', '/redirect']
router.beforeEach(async (to, from, next) => {
  NProgress.start()
  document.title = "恒天翼城市管理系统"
  if (whiteList.indexOf(to.path) !== -1) {
    next()
    NProgress.done()
  } else {
    const hasToken = await store.dispatch('user/getToken')
    if (hasToken) {
      try {
        const hasRoles = store.getters.user.roles && store.getters.user.roles.length > 0
        if (hasRoles) {
          next()
        } else {
          await store.dispatch('user/getUserInfo')
          const accessRoutes = await store.dispatch('permission/generateRoutes', store.getters.user.moduleList)
          router.addRoutes(accessRoutes)
          next({
            ...to,
            replace: true
          })
        }
      } catch (error) {
        NProgress.done()
      }
    } else {
      next(`/login?redirect=${to.path}`)
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})