import request from "@/utils/request";

export function getDataList(data) {
  return request({
    url: "/api/aboutUs/getDataList.json", //全部
    method: "post",
    data,
  });
}
export function saveData(data) {
  return request({
    url: "/api/aboutUs/saveData.json",
    method: "post",
    data,
  });
}
export function getDataInfo(data) {
  return request({
    url: "/api/aboutUs/getDataInfo.json",
    method: "post",
    data,
  });
}
export function deleteData(data) {
  return request({
    url: "/api/aboutUs/deleteData.json",
    method: "post",
    data,
  });
}
export function getAboutUs(data) {
  return request({
    url: "/api/aboutUs/getAboutUs.json",
    method: "post",
    data,
  });
}
