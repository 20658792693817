import * as aboutUs from "@/api/aboutUs";
const actions = {
  getDataList({}, parm) {
    return new Promise((resolve, reject) => {
      aboutUs.getDataList(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  saveData({}, parm) {
    return new Promise((resolve, reject) => {
      aboutUs.saveData(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getDataInfo({}, parm) {
    return new Promise((resolve, reject) => {
      aboutUs.getDataInfo(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteData({}, parm) {
    return new Promise((resolve, reject) => {
      aboutUs.deleteData(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getAboutUs({}, parm) {
    return new Promise((resolve, reject) => {
      aboutUs.getAboutUs(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
export default {
  namespaced: true,
  actions,
};
