import request from "@/utils/request";

export function getDataList(data) {
  return request({
    url: "/api/newsCenter/getDataList.json",
    method: "post",
    data,
  });
}
export function saveData(data) {
  return request({
    url: "/api/newsCenter/saveData.json",
    method: "post",
    data,
  });
}
export function getDataInfo(data) {
  return request({
    url: "/api/newsCenter/getDataInfo.json",
    method: "post",
    data,
  });
}
export function deleteData(data) {
  return request({
    url: "/api/newsCenter/deleteData.json",
    method: "post",
    data,
  });
}

