import request from "@/utils/request";

export function getDataList(data) {
  return request({
    url: "/api/contactInfo/getDataList.json", //全部
    method: "post",
    data,
  });
}
export function saveData(data) {
  return request({
    url: "/api/contactInfo/saveData.json",
    method: "post",
    data,
  });
}
export function getDataInfo(data) {
  return request({
    url: "/api/contactInfo/getDataInfo.json",
    method: "post",
    data,
  });
}
export function deleteData(data) {
  return request({
    url: "/api/contactInfo/deleteData.json",
    method: "post",
    data,
  });
}
export function getContactInfo(data) {
  return request({
    url: "/api/contactInfo/getContactInfo.json",
    method: "post",
    data,
  });
}
