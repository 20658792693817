import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Element from 'element-ui' 
import 'element-ui/lib/theme-chalk/index.css';
import './permission' 
import '@/styles/default.scss'  
import comFun from './comfun'

Vue.config.productionTip = false;
Vue.prototype.$comFun = comFun
Vue.use(comFun)
Vue.use(Element) 
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");