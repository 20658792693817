import * as dictionary from "@/api/dictionary";
const actions = {
  getDataList({}, parm) {
    return new Promise((resolve, reject) => {
      dictionary.getDataList(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getAllData({}, parm) {
    return new Promise((resolve, reject) => {
      dictionary.getAllData(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  saveData({}, parm) {
    return new Promise((resolve, reject) => {
      dictionary.saveData(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getDataInfo({}, parm) {
    return new Promise((resolve, reject) => {
      dictionary.getDataInfo(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteData({}, parm) {
    return new Promise((resolve, reject) => {
      dictionary.deleteData(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
export default {
  namespaced: true,
  actions,
};
