import * as sysModule from '@/api/sysModule'
const actions = {
  saveData({}, parm) {
    return new Promise((resolve, reject) => {
      sysModule.saveData(parm).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  moduleTree({}, parm) {
    return new Promise((resolve, reject) => {
      sysModule.moduleTree(parm).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  deleteData({}, parm) {
    return new Promise((resolve, reject) => {
      sysModule.deleteData(parm).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  actions
}